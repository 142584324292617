@import "/src/variables";
@import "/src/index.scss";

.home-main {
    margin-top: 70px;
    overflow-x: hidden;
    
    p{
        margin: 0;
    }
    .section-1 {
        background: $--my-black;
        height: 100vh;
        position: relative;
        overflow: hidden;
        .texts-container {
            display: flex;
            flex-direction: column;
            width: 60%;
            padding: 30px 0 0 60px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            h1 {
                font-family: "Bebas Neue", sans-serif;
                font-size: 160px;
                font-weight: 400;
                text-transform: uppercase;
                text-align: left;
                font-style: normal;
                line-height: 1;
                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0.3) 4.5%,
                    #fff 52.5%
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            p {
                color: $--primary-font-color;
                font-size: 28px;
                font-weight: 400;
                text-transform: capitalize;
                letter-spacing: 0.64px;
            }
            button {
                margin: 40px 0;
            }
        }
        .images-bg{
            width: 600px;
            height: 150%;
            position: absolute;
            top: -15%;
            right: 2%;
            transform: rotate(30deg);
            background: url("../../Assets/images/background-animation.png");
            background-size: cover;
            background-position-y: 0%;
            animation: images-animation 8s linear 0s infinite backwards;
            opacity: 0.6;
        }
        // .images{
        //     width: 600px;
        //     height: 150%;
        //     position: absolute;
        //     top: -15%;
        //     right: 2%;
        //     transform: rotate(30deg);
        //     display: grid;
        //     grid-template-columns: 1fr 1fr;
        //     gap: 20px;
        //     // opacity: 0.6;
        //     animation: images-animation 8s linear 0s infinite backwards;
        //     img{
        //         width: 290px;
        //     }
        // }
        @keyframes images-animation {
            0% {
                background-position-y: 100%;
            }
            100% {
                background-position-y: 0%;
            }
        }
    }
    .offer{
        background-color: $--my-red;
        padding: 8px 20px;
        color: $--my-white;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
    }
    .section-2{
        // background: linear-gradient(90deg, rgba(0, 0, 0, 0.40) 11.24%, rgba(0, 0, 0, 0.00) 75.54%), url("../../Assets/images/img9.svg") lightgray 50% / cover no-repeat;
        background-position-x: 60%;
        background-position-y: 15%;
        background-size: 130%;
        position: relative;
        overflow-y: hidden;
        .container{
            padding: 60px;
            display: flex;
            flex-direction: column;
            gap: 40px;

            color: $--my-white;
            font-family: Poppins;
            h2{
                font-size: 70px;
                font-weight: 700;
                text-transform: uppercase;
            }
            .timer{
                display: flex;
                gap: 25px;
                .time-unit{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    
                    .number{
                        font-size: 24px;
                        font-weight: 600;
                    }
                    .unit{
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }
        }
        .background-img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -1;
            height: 100%;
        }
    }
    .section-3{
        margin: 40px 0;

        .head{
            padding: 30px 60px;
            h3{
                color: $--my-black;
                font-size: 44px;
                font-weight: 700;
            }
            .text-container{
                display: flex;
                justify-content: space-between;
                gap: 30px;
                font-size: 28px;
                font-weight: 500;
                .shop{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;
                    a{
                        text-decoration: none;
                        color: black;
                    }
                    svg{
                        height: 30px;
                        width: 30px;
                    }
                }
            }
        }
        .images-container{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            width: 100vw;

            span{
                flex-grow: 1;
                width: 200px;
                max-width: 400px;
                cursor: pointer;

                img{
                    width: 100%;
                }
                .hover-image{
                    display: none;
                }
                .loading-statistics-container{
                    width: 100%;
                    height: 600px;
                }
            }
            span:hover{
                img, .main-img{
                    display: none;
                }
                .hover-image{
                    display: block;
                }
            }
        }
    }
}

@media (max-width: 1250px) {
    .home-main {
        .section-1 {
            .texts-container {
                h1{
                    font-size: 140px;
                }
            }
        }
        .section-3{
            .head{
                padding: 20px 40px;
            }
        }
    }
}

@media (max-width: 1000px) {
    .home-main {
        .section-1 {
            .texts-container {
                padding: 30px;
                width: 100%;
            }
        }
        .section-3{
            margin: 30px 0;
    
            .head{
                padding: 30px;
                h3{
                    font-size: 44px;
                }
                .text-container{
                    font-size: 30px;
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .home-main {
        .offer{
            font-size: 16px;
        }
        .section-2{
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.40) 11.24%, rgba(0, 0, 0, 0.00) 75.54%), url("../../Assets/images/img10.svg") lightgray 50% / cover no-repeat;
            background-position-x: 0%;
            background-position-y: 20%;
            background-size: 130%;
            .container{
                padding: 20px;
                font-weight: 700;
                line-height: normal;
                gap: 18px;
                h2{
                    font-size: 64px;
                }
            }
        }
        .section-3{
            .head{
                padding: 10px 20px;
                h3{
                    font-size: 36px;
                }
                .text-container{
                    font-size: 16px;
                    .shop{
                        svg{
                            height: 24px;
                            width: 24px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .home-main {
        .section-1 {
            .texts-container{
                top: unset;
                bottom: 100px;
                padding: 30px;
                h1{
                    font-size: 96px;
                }
                p{
                    display: none;
                }
                button{
                    margin: 30px 0;
                }
            }
            .images-bg{
                width: 100%;
            }
        }
        .offer{
            font-size: 14px;
        }
        .section-2{
            .container{
                padding: 20px;
                line-height: normal;
                gap: 18px;
                h2{
                    font-size: 50px;
                }
                .timer{
                    gap: 12px;
                    .time-unit{
                        gap: 6px;
                    }
                }
            }
        }
        .section-3{
            .head{
                padding: 10px 20px;
                h3{
                    font-size: 32px;
                }
                .text-container{
                    font-size: 12px;
                    .shop{
                        svg{
                            height: 16px;
                            width: 16px;
                        }
                    }
                }
            }
            .images-container{
                grid-template-columns: 1fr;
            }
        }
    }
}

@media (max-width: 400px) {
    .home-main {
        .section-1{
            .texts-container{
                padding: 20px;
            }
            .images-bg{
                width: 150%;
                top: -15%;
                right: -10%;
            }
        }
        .offer{
            font-size: 12px;
        }
        .section-2{
            .container{
                h2{
                    font-size: 38px;
                }
            }
        }
        .section-3{
            .head{
                padding: 10px;
            }
        }
    }
}
