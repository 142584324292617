@import "/src/variables";
@import "/src/index.scss";

.other-details-main {
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        margin: 0;
    }
    .main-image {
        max-height: 100vh;
        z-index: -1;
        width: 50%;
    }
    .my-container {
        display: grid;
        place-items: center;
        width: 100%;
        .other-details-container {
            width: min(90%, 500px);
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            h3 {
                color: $--my-black;
                font-size: 32px;
                font-weight: 700;
                margin: 0 20px;
                text-align: center;
            }
            .desc {
                color: $--my-black;
                text-align: center;
                font-size: 13px;
                font-weight: 500;
                margin: 0 60px;
            }
            .my-form {
                display: flex;
                flex-direction: column;
                gap: 15px;
                width: 100%;

                .gender {
                    display: flex;
                    justify-content: center;
                    gap: 20px;

                    .type {
                        height: 150px;
                        width: 150px;
                        border: 2px solid #000;
                        opacity: 0.2;
                        display: grid;
                        place-items: center;
                        svg{
                            path{
                                fill: black;
                            }
                        }
                        .check{
                            display: none;
                        }
                    }
                    .selected{
                        background-color: black;
                        opacity: 1;
                        position: relative;
                        svg{
                            path{
                                fill: white;
                            }
                        }
                        .check{
                            display: block;
                            position: absolute;
                            top: 10px;
                            right: 10px;
                        }
                    }
                }
                .input-grp {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;
                    span{
                        width: 100%;
                    }
                    input {
                        border: 1px solid #000;
                        padding: 12px 16px;
                        outline: none;
                        width: 100%;
                    }
                    p {
                        cursor: pointer;
                    }
                    .primary-btn-white-outline {
                        font-size: 14px;
                        text-wrap: nowrap;
                        height: 100%;
                        width: 170px;
                        display: grid;
                        place-items: center;
                        padding: 16px 0;
                    }
                }
                .check-boxes {
                    justify-content: flex-start;
                    gap: 30px;
                }
                .remember-forgot {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .buttons {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .socials {
                        display: flex;
                        gap: 20px;

                        .social-btn {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 16px 0;
                            gap: 8px;
                            border: none;
                            outline: none;

                            svg {
                                path {
                                    fill: white;
                                }
                            }
                            p {
                                color: white;
                                font-family: Poppins;
                                font-size: 13px;
                                font-weight: 600;
                            }
                        }
                        .facebook {
                            background: #3c66c5;
                        }
                        .google {
                            background: #d04333;
                        }
                    }
                    .primary-btn-black {
                        padding: 16px 0;
                        width: 100%;
                    }
                }
            }
        }
    }
}
@media (max-width: 1000px) {
    .other-details-main {
        grid-template-columns: 1fr;
        .main-image {
            display: none;
        }
    }
}


@media (max-width: 450px) {
    .other-details-main {
        .other-details-container {
            h3 {
                font-size: 24px;
            }
            .my-form {
                .input-grp {
                    gap: 10px;
                }
                .dates {
                    justify-content: flex-start;
                    flex-direction: column;
                    span {
                        width: 100%;
                    }
                }
            }
        }
    }
}
