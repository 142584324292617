@import "/src/variables";
@import "/src/index.scss";

.privacy-policy-main{
    margin-top: 70px;
    padding: 40px;
    h3{
        font-family: "Bebas Neue";
        font-size: 64px;
        font-weight: 400;
        color: $--my-black;
    }
    .points{
        display: flex;
        flex-direction: column;
        gap: 20px;

        .point{
            color: $--my-black;
            display: flex;
            flex-direction: column;
            gap: 10px;
            li{
                font-weight: 500;
                font-size: 16px;
            }
            div{
                margin-left: -1rem;
                font-weight: 300;
            }
        }
    }
}
@media (max-width: 1000px){
    .privacy-policy-main{
        padding: 30px;
    }
}
@media (max-width: 800px){
    .privacy-policy-main{
        padding: 20px;
    }
}
