.order-success-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    gap: 50px;

    h1{
        color: #000;
        font-family: "Bebas Neue";
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.28px;
        text-transform: uppercase;
    }
    p{
        color: #000;
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 128.571% */
        letter-spacing: 0.16px;
        width: min(90%, 800px);
    }
}