.search-input {
    width: 100%;
    position: absolute;
    z-index: 111;
    padding: 0 20px;
    left: 0;
    top: 80px;
    // display: flex;
    .cross-search {
        // display: none;
        position: absolute;
        top: 50%;
        right: 28px;
        transform: translatey(-50%);
        height: 30px;
        width: 30px;
        path{
            fill: black;
        }
    }
    input[type=text]:focus{
        border: none !important;
    }
    .sc-gEvEer{
        z-index: 11111;
    }
  .sc-gEvEer{
        .line{
            margin-left: 0px !important;
            padding: 0px !important;
        }
    }
}

@media only screen and (max-width: 800px) {
    .search-input {
        top: 15px;
    }
}