@import "/src/variables";
@import "/src/index.scss";

.nav-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $--my-black;
    padding: 20px 40px;
    color: $--primary-font-color;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 2;
    min-height: 75px;

    p {
        margin: 0;
    }

    .part {
        display: flex;
        align-items: center;
        gap: 30px;
        span {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 20px;
            font-weight: 500;
            text-wrap: nowrap;
            cursor: pointer;
            position: relative;

            p {
                font-weight: 650;
            }
            .number{
                display: grid;
                place-items: center;
                // background-color: white;
                // color: black;
                border-radius: 50%;
                height: 20px;
                width: 20px;
                font-size: 14px;
                position: absolute;
                top: -14px;
                right: -14px;
            }

            .normal-icon {
                height: 30px;
                width: 30px;
                path {
                    fill: $--my-white;
                }
            }
        }
        .disable-on-desktop {
            display: none;
        }
    }
    .part-2{
        width: 100%;
        justify-content: center;

        svg{
            cursor: pointer;
        }
    }
}
.login-nav{
    justify-content: space-between;
    .part-2{
        width: unset;
    }
}

@media (max-width: 1000px) {
    .nav-main {
        padding: 20px;

        .part {
            gap: 25px;
            span {
                font-size: 20px;

                .normal-icon {
                    height: 28px;
                    width: 28px;
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .nav-main {
        padding: 20px;

        .part {
            gap: 20px;
            span {
                display: none;
            }
            .show-on-mobile {
                display: unset;
            }
        }
        .part-1 {
            display: none;
        }
        .part-2 {
            width: unset;
            justify-content: flex-start;
            svg {
                height: 16px;
                width: 150px;
            }
        }
    }
}
