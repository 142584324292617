@import "/src/variables";
@import "/src/index.scss";

.forgot-password-main{
    height: 100vh;
    display: grid;
    place-items: center;

    p{
        margin: 0;
    }
    .forgot-password-container{
        width: min(90%, 500px);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        h3{
            color: $--my-black;
            font-size: 32px;
            font-weight: 700;
            margin: 0 20px;
            text-align: center;
        }
        .my-form{
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 100%;
            .input-grp{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 20px;
                input{
                    border: 1px solid #000;
                    padding: 16px 20px;
                    outline: none;
                    width: 100%;
                }
                svg{
                    path{
                        fill: black;
                    }
                }
                .check-box-container{
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    cursor: pointer;
                }
                p{
                    cursor: pointer;
                }
                .primary-btn-white-outline{
                    font-size: 14px;
                    text-wrap: nowrap;
                    height: 100%;
                    width: 170px;
                    display: grid;
                    place-items: center;
                    padding: 16px 0;
                }
            }
            .check-boxes{
                justify-content: flex-start;
                gap: 30px;
            }
            .remember-forgot{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .buttons{
                display: flex;
                flex-direction: column;
                gap: 20px;

                .socials{
                    display: flex;
                    gap: 20px;

                    .social-btn{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 16px 0;
                        gap: 8px;
                        border: none;
                        outline: none;

                        svg{
                            path{
                                fill: white;
                            }
                        }
                        p{
                            color: white;
                            font-family: Poppins;
                            font-size: 13px;
                            font-weight: 600;
                        }
                    }
                    .facebook{
                        background: #3C66C5;
                    }
                    .google{
                        background: #D04333;
                    }
                    }
                        .primary-btn-black{
                            padding: 16px 0;
                            width: 100%;
                        }
            }
        }
    }
}

@media (max-width: 450px){
    .forgot-password-main{
        padding-top: 95px;

        .forgot-password-container{
            h3{
                font-size: 24px;
            }
            .my-form{
                .input-grp{
                    gap: 10px;
                }
                .dates{
                    justify-content: flex-start;
                    flex-direction: column;
                    span{
                        width: 100%;
                    }
                }
            }
        }
    }
}