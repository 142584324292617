@import "/src/variables";
@import "/src/index.scss";

.order-details-model {
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow-y: hidden;
    width: min(100%, 900px);

    
    p{
        margin: 0;
    }
    .head {
        color: var(--text-color, #252b42);
        font-size: 20px;
        font-weight: 650;
        text-align: center;
        padding: 0 20px;
    }
    .close-icon {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        path {
            fill: $--my-black;
        }
    }
    .product-items {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-height: 300px;
        overflow-y: scroll;
        padding-right: 20px;
        width: 100%;
        
        .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 12px;
            flex-wrap: wrap;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            img {
                width: 50px;
                cursor: pointer;
            }
            .loading-statistics-container {
                width: 60px;
                max-height: 625px;
                aspect-ratio: 0.66;
            }
            .name {
                cursor: pointer;
                p {
                    color: #c4c4c4;
                    font-size: 13px;
                    font-weight: 300;
                }
                .bold {
                    color: $--my-black;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .detail {
                font-size: 14px;
                font-weight: 400;
                margin-left: 20px;
                width: fit-content;
            }
            .price {
                font-size: 16px;
                font-weight: 500;
                margin-left: 20px;
                width: fit-content;
            }
            .link {
                color: #004adb;
                font-size: 16px;
                font-weight: 500;
                text-decoration-line: underline;
                cursor: pointer;
            }
        }
    }
    .summary {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        .title {
            color: #ccc;
            font-size: 16px;
            font-weight: 500;
        }
        .details {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            p {
                font-size: 16px;
                font-weight: 400;
            }
        }
    }
    .primary-btn-black {
        padding: 16px 24px;
        font-size: 14px;
        font-weight: 500;
    }
}

@media (max-width: 400px){
    .order-details-model{
        .product-items{
            .item{
                .detail, .link{
                    margin-left: 0;
                }
            }
        }
    }
}