@import "/src/variables";
@import "/src/index.scss";

.otp-verification-main{
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p{
        margin: 0;
    }
    .main-image{
        max-height: 100vh;
        z-index: -1;
        width: 50%;
    }
    .otp-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        h3{
            color: $--my-black;
            font-size: 32px;
            font-weight: 700;
            margin: 0 60px;
        }
        .desc{
            color: $--my-black;
            text-align: center;
            font-size: 13px;
            font-weight: 500;
            margin: 0 60px;
        }
        .otp{
            display: flex;
            gap: 16px;

            input{
                height: 36px;
                width: 36px;
                outline: none;
                padding: 10px;
                font-size: 24px;
            }
        }
        .primary-btn-black{
            width: min(400px, 95%);
        }
        .did{
            color: #808080;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            span{
                color: #000;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: capitalize;
                cursor: pointer;
            }
        }
    }
}
@media (max-width: 1000px){
    .otp-verification-main{
        .main-image{
            display: none;
        }
    }
}