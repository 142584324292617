@import "/src/variables";
@import "/src/index.scss";

.wishlist-main {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    .header {
        padding: 60px 60px 0 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3 {
            font-size: 64px;
            font-weight: 400;
            margin: 0;
            font-family: "Bebas Neue";
        }
        p{
            color: #969696;
            font-size: 24px;
            font-weight: 650;
        }
    }
    hr{
        margin: 0;
    }
    .products {
        padding: 0px 60px 60px 60px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 16px;
        .product {
            margin-bottom: 30px;
            cursor: pointer;
            img {
                width: 100%;
                max-height: 625px;
            }
            .loading-statistics-container{
                width: 100%;
                max-height: 625px;
                aspect-ratio: 0.66;
              }
            .content{
                font-size: 22px;
                .line1{
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    .title{
                        color: $--my-black;
                        font-weight: 400;
                    }
                    .tag{
                        color: $--secondary-font-color;
                        font-size: 20px;
                        font-weight: 500;
                        
                    }
                }
                .bold{
                    color: $--my-black;
                    font-weight: 650;
                }
            }
        }
    }
}

@media (max-width: 1250px) {
    .wishlist-main {
        .products {
            .product {
                .content{
                    font-size: 18px;
                    .line1{
                        .tag{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1000px) {
    .wishlist-main {
        .header{
            padding: 30px 30px 0 30px;
            h3{
                font-size: 48px;
            }
            p{
                font-size: 14px;
            }
        }
        .products {
            padding: 0px 30px 20px 30px;
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}
@media (max-width: 800px) {
    .wishlist-main {
        .products {
            .product {
                .content{
                    font-size: 14px;
                    .line1{
                        .tag{
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 600px) {
    .wishlist-main {
        .products {
            grid-template-columns: 1fr 1fr;
            gap: 12px;
            .product {
                margin-bottom: 10px;
                .content{
                    font-size: 14px;
                    .line1{
                        .tag{
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 400px) {
    .wishlist-main {
        .products {
            .product {
                .content{
                    font-size: 10px;
                    .line1{
                        .tag{
                            font-size: 8px;
                        }
                    }
                }
            }
        }
    }
}