@import "/src/variables";
@import "/src/index.scss";

.profile-main {
    margin-top: 70px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    p {
        margin: 0;
    }
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3 {
            font-size: 64px;
            font-weight: 400;
            color: $--my-black;
            font-family: "Bebas Neue";
            margin: 0;
        }
        .buttons {
            display: flex;
            align-items: center;
            gap: 24px;
            .option {
                display: flex;
                align-items: center;
                border: 1px solid #e6e6e6;
                background: #f9f9f9;
                padding: 4px;
                cursor: pointer;

                span {
                    padding: 12px;
                    background: $--my-black;
                    display: grid;
                    align-items: center;
                }
                p {
                    padding: 12px;
                    font-size: 14px;
                    font-weight: 650;
                }
            }
            .logout {
                padding: 16px 20px;
                height: 100%;
                display: flex;
                gap: 12px;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
    hr {
        margin: 0;
    }
    .details-container {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        .address-container {
            width: 35%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .profile-editor{
                display: flex;
                flex-direction: column;
                gap: 16px;
                label {
                  font-size: 16px;
                  font-weight: 500;
                  color: #CCC;
                  
                }
                .input-grp {
                    display: flex;
                    gap: 16px;
                    width: 100%;
              
                    input {
                      padding: 10px 0px;
                      font-weight: 500;
                      width: 100%;
                      outline: none;
                      border: none;
                      font-size: 20px;
                    }
                    span{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        text-wrap: nowrap;
                        cursor: pointer;
                    }
                    input:disabled{
                        background-color: transparent;
                    }
                  }
                  .selected{
                    input{
                        border: 1px solid black;
                        padding: 10px 16px;
                        font-size: 16px;
                    }
                  }
            }
        }
        .order-details {
            width: 65%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .head {
                display: flex;
                justify-content: space-between;
                .title {
                    color: #ccc;
                    font-size: 16px;
                    font-weight: 500;
                }
                .policy {
                    color: #002cc8;
                    font-size: 16px;
                    font-weight: 500;
                    text-decoration-line: underline;
                    cursor: pointer;
                }
            }
            .product-items {
                display: flex;
                flex-direction: column;
                gap: 20px;
                max-height: 570px;
                overflow-y: scroll;
                padding-right: 20px;

                .item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 30px;
                    .details {
                        display: flex;
                        align-items: center;
                        // display: grid;
                        // grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
                        gap: 20px;
                        // width: 100%;
                        flex-grow: 1;

                        img {
                            width: 60px;
                        }
                        .loading-statistics-container {
                            width: 80px;
                            max-height: 625px;
                            aspect-ratio: 0.66;
                        }
                        .my-text{
                            display: flex;
                            gap: 20px;
                            width: 100%;
                            justify-content: space-evenly;
                            align-items: center;
                        }
                        .name {
                            p {
                                color: #c4c4c4;
                                font-size: 13px;
                                font-weight: 300;
                            }
                            .bold {
                                color: $--my-black;
                                font-size: 16px;
                                font-weight: 500;
                            }
                        }
                        .detail {
                            font-size: 14px;
                            font-weight: 400;
                        }
                        .price {
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }
                    .buttons {
                        display: flex;
                        gap: 12px;

                        .my-btn {
                            color: var(--text-color, #252b42);
                            font-size: 14px;
                            text-transform: uppercase;
                        }
                        .my-btn.label{
                            cursor: default;
                            background: none;
                            text-align: center;
                        }
                        .primary-btn-black {
                            font-size: 14px;
                            font-weight: 500;
                            padding: 12px 16px;
                        }
                        .disable{
                            color: rgba(37, 43, 66, 0.40);
                            
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1100px){
    .profile-main{
        padding: 40px;
        .details-container{
            flex-direction: column;
            .address-container{
                width: 100%;
            }
            .order-details{
                width: 100%;
                .product-items{
                    .item{
                        flex-direction: column;
                        .details{
                            justify-content: space-between;
                            width: 100%;
                        }
                        .buttons{
                            width: 100%;
                            button{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px){
    .profile-main{
        padding: 20px;
        .header{
            .buttons{
                flex-direction: column;
            }
        }
        .details-container{
            .order-details{
                .product-items{
                    .item{
                        .details{
                            .my-text{
                                flex-direction: column;
                                gap: 0;
                            }
                        }
                        .buttons{
                            flex-wrap: wrap;
                            .my-btn{
                                width: 40%;
                                flex-grow: 1;
                            }
                            .primary-btn-black{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}