@import "/src/variables";
@import "/src/index.scss";

.cart-model-main {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0 30px 0;
    width: 100%;
    max-width: 450px;
    max-height: min(650px, 100%);

    height: fit-content;
    background-color: $--my-white;
    color: $--my-black;

    svg {
        path {
            fill: $--my-black;
        }
    }

    .head {
        display: flex;
        width: 100%;
        p {
            font-family: "Bebas Neue";
            font-size: 50px;
            font-weight: 400;
            width: 100%;
            text-align: center;
        }
        svg {
            cursor: pointer;
        }
    }
    .number-of-items {
        background: rgba(0, 0, 0, 0.03);
        text-align: center;
        width: 100%;
        padding: 10px;
        color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        font-weight: 400;
    }
    .cart-items {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        min-height: 300px;
        overflow-y: scroll;
        gap: 10px;
        // padding-top: 100%;
        width: 100%;

        .empty {
            text-align: center;
            font-size: 20px;
            font-weight: 400;
            text-transform: capitalize;
        }

        .cart-item {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            width: 100%;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            img {
                width: 80px;
            }
            .loading-statistics-container {
                width: 80px;
                max-height: 625px;
                aspect-ratio: 0.66;
            }
            .details {
                display: flex;
                flex-direction: column;
                gap: 10px;
                color: #393939;
                font-family: Raleway;
                font-size: 16px;
                font-weight: 400;

                span {
                    color: #393939;
                    font-weight: 600;
                }
            }
            .sign-price {
                display: flex;
                align-items: center;
                gap: 20px;
                .quantity {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    border: 1px solid #000;

                    span {
                        font-size: 14px;
                        font-weight: 500;
                    }
                    .sign {
                        padding: 10px 14px;
                        font-size: 20px;
                        cursor: pointer;
                    }
                    .sign:hover {
                        background-color: #f9f9f9;
                    }
                }
                .price {
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }
        .cart-item:last-child {
            border-bottom: none;
        }
    }
    .empty-cart {
        padding: 20px;
        min-height: 40vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
    }
    .primary-btn-black {
        padding: 16px 30px;
        font-size: 16px;
        font-weight: 650;
        margin-top: 20px;
    }
}

@media (max-width: 600px) {
    .cart-model-main {
        .cart-items {
            .cart-item {
                .sign-price {
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .cart-model-main {
        max-width: 320px;
        .cart-items {
            padding-top: 430px;
        }
    }
}
