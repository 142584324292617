@import "/src/variables";
@import "/src/index.scss";

.product-details-main {
    padding: 160px 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .disabled{
        cursor: not-allowed;
    }
    p {
        margin: 0;
    }
    .section-1 {
        display: flex;
        gap: 40px;
        .images {
            display: flex;
            align-items: flex-start;
            gap: 20px;
            width: 55%;

            .sub-images {
                display: flex;
                flex-direction: column;
                gap: 20px;
                img {
                    width: 100px;
                    cursor: pointer;
                }
                .loading-statistics-container {
                    width: 100px;
                    height: 150px;
                }
            }
            .main-image {
                width: calc(100% - 120px);
            }
            .zoomed-img{
                width: 50%;
                z-index: 1;
            }
            .main-image-hover {
                height: 80%;
            }
        }
        .mobile-images {
            display: flex;
            gap: 10px;
            width: 100%;
            overflow-x: scroll;
            display: none;
            img {
                max-width: 80%;
                max-height: 500px;
            }
            .loading-statistics-container {
                height: 500px;
                width: 100%;
            }
        }
        .text-container {
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 45%;
            h3 {
                color: $--my-black;
                font-size: 48px;
                font-weight: 400;
                text-transform: capitalize;
            }
            .price-container {
                .price {
                    color: $--secondary-dark-font-color;
                    font-size: 32px;
                    font-weight: 500;
                }
                .instruction {
                    color: $--secondary-dark-font-color;
                    font-size: 12px;
                    font-weight: 300;
                    span {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
            hr {
                border-color: #777375;
                margin: 0;
            }
            .size-coins {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                .sizes {
                    p {
                        color: $--secondary-dark-font-color;
                        font-size: 16px;
                        font-weight: 300;
                        margin-bottom: 6px;
                    }
                    .size-list {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 20px;
                        .size {
                            padding: 8px 18px;
                            border: 1px solid $--secondary-font-color;
                            color: $--secondary-font-color;
                            font-size: 16px;
                            font-weight: 300;
                            cursor: pointer;
                        }
                        .size:hover {
                            border-color: $--secondary-dark-font-color;
                            color: $--secondary-dark-font-color;
                        }
                        .selected {
                            border-color: $--my-black;
                            color: $--my-black;
                            font-weight: 600;
                        }
                    }
                }
                .coins {
                    color: $--my-black;
                    font-size: 24px;
                    font-weight: 500;
                    display: flex;
                    gap: 10px;
                    align-items: center;

                    svg {
                        height: 24px;
                        width: 24px;
                    }
                }
            }
            .buttons {
                display: flex;
                flex-direction: column;
                gap: 16px;
                button {
                    width: 100%;
                }

                div {
                    display: flex;
                    gap: 16px;
                }
            }
            .description {
                padding: 0 25px;
                color: $--my-black;
                font-size: 16px;
                font-weight: 300;
                display: flex;
                flex-direction: column;
                gap: 6px;
            }
            .other-detail {
                display: flex;
                flex-direction: column;
                gap: 6px;
                p {
                    font-size: 16px;
                    color: $--my-black;
                    font-weight: 300;
                }
                .bold {
                    font-weight: 500;
                }
            }
        }
    }
    .section-2 {
        margin: 60px 0;

        .head {
            h3 {
                color: $--my-black;
                font-size: 48px;
                font-weight: 700;
                text-transform: uppercase;
            }
            .text-container {
                display: flex;
                justify-content: space-between;
                gap: 30px;
                font-size: 24px;
                font-weight: 500;
                .shop {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;
                    svg {
                        height: 34px;
                        width: 34px;
                    }
                }
            }
        }
        .products {
            padding: 20px 0px 20px 0px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 16px;
            .product {
                cursor: pointer;
                img {
                    width: 100%;
                    // max-height: 625px;
                }
                .loading-statistics-container {
                    width: 100%;
                    max-height: 625px;
                    aspect-ratio: 0.66;
                }
                .content {
                    font-size: 22px;
                    .line1 {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        .title {
                            color: $--my-black;
                            font-weight: 400;
                        }
                        .tag {
                            color: $--secondary-font-color;
                            font-size: 20px;
                            font-weight: 500;
                        }
                    }
                    .bold {
                        color: $--my-black;
                        font-weight: 650;
                    }
                }
            }
        }
    }
}
@media (max-width: 1250px) {
    .product-details-main {
        padding: 140px 40px 0;
        .section-1 {
            .text-container {
                h3 {
                    font-size: 42px;
                }
                .price-container {
                    .price {
                        font-size: 28px;
                    }
                }
                .buttons {
                    button {
                        font-size: 16px;
                    }
                    div {
                        flex-direction: column;
                    }
                }
            }
        }
        .section-2 {
            .products {
                .product {
                    .content {
                        font-size: 16px;
                        .line1 {
                            .tag {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1000px) {
    .product-details-main {
        .section-1 {
            flex-direction: column;
            gap: 20px;
            .images {
                display: none;
            }
            .mobile-images {
                display: flex;
            }
            .text-container {
                width: 100%;
                gap: 20px;
                .buttons {
                    div {
                        flex-direction: row;
                    }
                }
            }
        }
        .section-2 {
            margin: 20px 0;
            .head {
                h3 {
                    font-size: 44px;
                }
                .text-container {
                    font-size: 24px;
                }
            }
            .products {
                .product {
                    .content {
                        font-size: 14px;
                        .line1 {
                            .tag {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 800px) {
    .product-details-main {
        padding: 100px 20px 0 20px;
        .section-2 {
            .head {
                h3 {
                    font-size: 36px;
                }
                .text-container {
                    font-size: 16px;
                    .shop {
                        svg {
                            height: 24px;
                            width: 24px;
                        }
                    }
                }
            }
            .products {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}
@media (max-width: 600px) {
    .product-details-main {
        .section-1 {
            .text-container {
                .buttons {
                    gap: 8px;
                    div {
                        gap: 8px;
                    }
                    button {
                        font-size: 12px;
                        padding: 10px 20px;
                        svg {
                            min-height: 16px;
                            min-width: 16px;
                            height: 20px;
                            width: 20px;
                        }
                    }
                }
            }
        }
        .section-2 {
            .head {
                h3 {
                    font-size: 32px;
                }
                .text-container {
                    font-size: 12px;
                    .shop {
                        svg {
                            height: 16px;
                            width: 16px;
                        }
                    }
                }
            }
            .products {
                grid-template-columns: 1fr;
                gap: 0;
                .product {
                    .content {
                        display: none;
                    }
                }
            }
        }
    }
}
@media (max-width: 450px) {
    .product-details-main {
        .section-1 {
            .text-container {
                .buttons {
                    gap: 8px;
                    div {
                        flex-direction: column;
                    }
                }
            }
        }
    }
}
