@import "/src/variables";
@import "/src/index.scss";

.products-main {
  margin-top: 70px;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 55px 80px 20px 80px;
    h2 {
      color: $--my-black;
      font-family: "Bebas Neue";
      font-size: 64px;
      font-weight: 400;
      letter-spacing: 1.28px;
      text-transform: uppercase;
    }
    p {
      color: $--secondary-font-color;
      font-size: 24px;
      font-weight: 650;
      text-transform: uppercase;
    }
  }
  .filters {
    padding: 20px 80px 20px 80px;
    display: flex;
    gap: 16px;
    align-items: center;

    p {
      color: $--secondary-font-color;
      font-size: 28px;
      font-weight: 400;
    }
    select {
      padding: 16px 24px;
      padding-right: 50px;
      border: 1px solid #e6e6e6;
      background: #f9f9f9;
      outline: none;
      color: var(--text-color, #252b42);
      font-size: 14px;
      font-weight: 600;
      background-image: url("../../Assets/icons/down-arrow.svg");
      background-repeat: no-repeat;
      // background-size: 20px;
      background-position-y: 50%;
      background-position-x: calc(100% - 20px);
      
      -webkit-appearance:none;
      -moz-appearance:none;
      appearance:none;
    }
  }
  .products {
    padding: 20px 80px 20px 80px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
    .product {
      margin-bottom: 30px;
      cursor: pointer;
      img {
        width: 100%;
        max-height: 625px;
      }
      .loading-statistics-container{
        width: 100%;
        max-height: 625px;
        aspect-ratio: 0.66;
      }
      .content {
        font-size: 22px;
        .line1 {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .title {
            color: $--my-black;
            font-weight: 400;
          }
          .tag {
            color: $--secondary-font-color;
            font-size: 20px;
            font-weight: 500;
          }
        }
        .bold {
          color: $--my-black;
          font-weight: 650;
        }
      }
    }
  }
  .not-found {
    text-align: center;
    padding: 20px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }
  .filters-mobile {
    display: none;
    position: sticky;
    bottom: 0;
    select {
      width: 50%;
      padding: 16px 24px;
      padding-right: 50px;
      border: 1px solid #e6e6e6;
      background: #f9f9f9;
      background-size: 20px;
      outline: none;
      color: var(--text-color, #252b42);
      font-size: 14px;
      font-weight: 600;
      background-image: url("../../Assets/icons/down-arrow.svg");
      background-repeat: no-repeat;
      background-size: 20px;
      background-position-y: 50%;
      background-position-x: calc(100% - 20px);
      
      -webkit-appearance:none;
      -moz-appearance:none;
      appearance:none;
    }
  }
}

@media (max-width: 1250px) {
  .products-main {
    .head {
      padding: 40px 60px 20px 60px;
      h2 {
        font-size: 52px;
      }
      p {
        font-size: 20px;
      }
    }
    .filters {
      padding: 40px 60px 0px 60px;
      p {
        font-size: 24px;
      }
      select {
        padding: 12px 20px;
        padding-right: 50px;
        font-size: 12px;
      }
    }
    .products {
      padding: 40px 60px 20px 60px;
      .product {
        .content {
          font-size: 18px;
          .line1 {
            .tag {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  .products-main {
    .head {
      padding: 30px 40px 20px 40px;
      h2 {
        font-size: 42px;
      }
    }
    .filters {
      padding: 30px 40px 0px 40px;
    }
    .products {
      padding: 30px 40px 20px 40px;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
@media (max-width: 800px) {
  .products-main {
    // margin-top: 75px;
    .products {
      .product {
        .content {
          font-size: 14px;
          .line1 {
            .tag {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .products-main {
    .head {
      border-bottom: none;
      padding: 30px 20px 0px 20px;
      h2 {
        font-size: 32px;
      }
      p {
        font-size: 12px;
      }
    }
    .filters {
      display: none;
    }
    .products {
      padding: 30px 20px 0px 20px;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
      .product {
        margin-bottom: 10px;
        .content {
          font-size: 14px;
          .line1 {
            .tag {
              font-size: 10px;
            }
          }
        }
      }
    }
    .filters-mobile {
      display: flex;
    }
  }
}
@media (max-width: 400px) {
  .products-main {
    .products {
      .product {
        .content {
          font-size: 10px;
          .line1 {
            .tag {
              font-size: 8px;
            }
          }
        }
      }
    }
  }
}
