@import "/src/variables";
@import "/src/index.scss";

.address-main {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  width: 100%;

  .inputs-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    width: 100%;

    .input-grp {
      display: flex;
      flex-direction: column;
      gap: 6px;

      label {
        font-size: 16px;
        font-weight: 500;
      }
      input {
        padding: 14px 20px;
        font-weight: 500;
      }
    }
  }
  .action {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    gap: 20px;
    .button {
      padding: 16px 27px;
      font-size: 16px;
      width: 100%;
      justify-content: center;
    }
  }
}
@media (max-width: 1000px) {
  .address-main {
    .inputs-container {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }
}
@media (max-width: 900px) {
  .address-main {
    .inputs-container {
      grid-template-columns: 1fr 1fr;
    }
  }
}
@media (max-width: 600px) {
  .address-main {
    .inputs-container {
      grid-template-columns: 1fr;
    }
  }
}
