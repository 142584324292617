@import "/src/variables";
@import "/src/index.scss";

.contact-us-main{
    margin-top: 70px;
    padding: 60px 150px;

    .head{
        display: flex;
        justify-content: space-between;
        gap: 80px;
        h3{
            font-family: "Bebas Neue";
            font-size: 96px;
            font-weight: 400;
            color: $--my-black;
        }
        p{
            color: $--my-black;
            font-size: 24px;
            font-weight: 300;
        }
    }
    .contact-form{
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: min(100%, 700px);

        .input-container{
            display: flex;
            gap: 20px;
        }
        .input-grp{
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;
            label {
                font-size: 16px;
                font-weight: 500;
            }
            input, textarea {
                padding: 14px 20px;
                font-weight: 500;
                width: 100%;
            }
            textarea{
                height: 200px;
            }
            .form-error{
                position: relative;
                top: -10px;
                margin: 0;
                height: 0;
            }
        }
        button {
            padding: 16px 27px;
            font-size: 16px;
            justify-content: center;
        }
    }
}
@media (max-width: 1250px){
    .contact-us-main{
        .head{
            h3{
                font-size: 64px;
            }
        }
    }
}
@media (max-width: 1000px){
    .contact-us-main{
        padding: 60px;
        .head{
            p{
                font-size: 20px;
            }
        }
    }
}
@media (max-width: 800px){
    .contact-us-main{
        padding: 40px;
        .head{
            flex-direction: column;
            gap: 20px;

            svg{
                width: 100%;
                height: 160px;
            }
        }
    }
}
@media (max-width: 600px){
    .contact-us-main{
        padding: 20px;
    }
}
@media (max-width: 400px){
    .contact-us-main{
        .contact-form{
            .input-container{
                flex-direction: column;
            }
        }
    }
}