@import "/src/variables";
@import "/src/index.scss";

.faqs-main {
    position: relative;
    padding: 40px;
    margin-top: 70px;
    padding-bottom: 108px;

    h3{
        font-family: "Bebas Neue";
        font-size: 64px;
        font-weight: 400;
        color: $--my-black;
    }
    .each-faq {
        background-color: $--my-black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 24px;
        color: $--my-white;
        .close{
            transform: rotate(180deg);
        }
        svg{
            height: 24px;
            width: 24px;
            path{
                fill: $--my-white;
            }
        }
    }

    .each-faq:hover {
        cursor: pointer;
    }

    .each-faq-ans {
        margin: -3px 0px 20px 0px;
        padding: 12px 24px;
        color: $--my-black;
        background: #F5F5F5;
        font-weight: 400;
    }
}

@media (max-width: 1000px){
    .faqs-main{
        padding: 30px;
    }
}
@media (max-width: 800px){
    .faqs-main{
        padding: 20px;
    }
}
