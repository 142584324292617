@import "/src/variables";
@import "/src/index.scss";

.google-login-main {
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        margin: 0;
    }
    .main-image {
        max-height: 100vh;
        z-index: -1;
        width: 50%;
    }
    .my-container {
        display: grid;
        place-items: center;
        width: 100%;
        .google-login-container {
            width: min(90%, 500px);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;

            h3 {
                color: $--my-black;
                font-size: 32px;
                font-weight: 700;
                margin: 0 60px;
            }
            .desc {
                color: $--my-black;
                text-align: center;
                font-size: 13px;
                font-weight: 500;
                margin: 0 60px;
            }
            .my-form {
                display: flex;
                flex-direction: column;
                gap: 15px;
                width: 100%;
                .input-grp {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 1px solid #000;
                    padding: 16px 20px;
                    input {
                        border: none;
                        outline: none;
                        width: 100%;
                    }
                    svg {
                        path {
                            fill: black;
                        }
                    }
                }
                .remember-forgot {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .remember {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        cursor: pointer;
                    }
                    p {
                        cursor: pointer;
                    }
                }
                .buttons {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .socials {
                        display: flex;
                        gap: 20px;

                        .social-btn {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 16px 0;
                            gap: 8px;
                            border: none;
                            outline: none;

                            svg {
                                path {
                                    fill: white;
                                }
                            }
                            p {
                                color: white;
                                font-family: Poppins;
                                font-size: 13px;
                                font-weight: 600;
                            }
                        }
                        .facebook {
                            background: #3c66c5;
                        }
                        .google {
                            background: #d04333;
                        }
                    }
                    .primary-btn-black {
                        padding: 16px 0;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .google-login-main {
        grid-template-columns: 1fr;
        .main-image {
            display: none;
        }
    }
}
