@import "/src/variables";
@import "/src/index.scss";

.checkout-main {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    padding: 40px;
    h3 {
        font-family: "Bebas Neue";
        font-size: 52px;
        font-weight: 400;
    }
    p {
        margin: 0;
    }
    .backdrop {
        background-color: transparent;
    }
    .checkout-container {
        display: flex;
        gap: 20px;
        .cart-details {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 50%;

            .cart-icon {
                display: flex;
                gap: 8px;
                align-items: center;

                p {
                    color: #808080;
                    font-size: 16px;
                    font-weight: 650;
                }
            }
            .product-items {
                display: flex;
                flex-direction: column;
                gap: 10px;
                max-height: 300px;
                overflow-y: scroll;
                padding-right: 20px;

                .item {
                    display: grid;
                    grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
                    place-items: center;
                    gap: 8px;
                    img {
                        width: 60px;
                    }
                    .loading-statistics-container {
                        width: 100%;
                        max-height: 625px;
                        aspect-ratio: 0.66;
                    }
                    .name {
                        p {
                            color: #c4c4c4;
                            font-size: 13px;
                            font-weight: 300;
                        }
                        .bold {
                            color: $--my-black;
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }
                    .detail {
                        font-size: 14px;
                        font-weight: 400;
                        .quantity {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            border: 1px solid #000;

                            span {
                                font-size: 14px;
                                font-weight: 500;
                            }
                            .sign {
                                padding: 10px 14px;
                                font-size: 20px;
                                cursor: pointer;
                            }
                            .sign:hover {
                                background-color: #f9f9f9;
                            }
                        }
                    }
                    .price {
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
            .total-breakdown {
                display: flex;
                flex-direction: column;
                gap: 8px;
                p {
                    display: flex;
                    justify-content: space-between;
                    padding-right: 20px;
                    span {
                        font-weight: 600;
                    }
                }
            }
            .coupon {
                display: flex;
                flex-direction: column;
                gap: 10px;
                position: relative;
                border: 1px solid #808080;
                padding: 20px;
                border-radius: 8px;
                .title {
                    color: #808080;
                    font-size: 16px;
                    font-weight: 650;
                }
                input {
                    width: 100%;
                    border: 1px solid #000;
                    padding: 14px 20px;
                    font-size: 13px;
                    font-weight: 650;
                }
                .coupon-codes {
                    // display: none;
                    position: absolute;
                    top: calc(100% - 10px);
                    background-color: $--my-white;
                    box-shadow: -90px 176px 55px 0px rgba(0, 0, 0, 0),
                        -57px 113px 51px 0px rgba(0, 0, 0, 0.01),
                        -32px 63px 43px 0px rgba(0, 0, 0, 0.05),
                        -14px 28px 32px 0px rgba(0, 0, 0, 0.09),
                        -4px 7px 17px 0px rgba(0, 0, 0, 0.1);

                    width: calc(100% - 40px);
                    z-index: 1;
                    padding: 20px;
                    max-height: 300px;
                    overflow-y: scroll;
                    .code {
                        display: flex;
                        gap: 16px;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid #ebebeb;
                        padding: 12px 0;
                        padding-right: 12px;
                        cursor: pointer;
                        .code-details-container {
                            display: flex;
                            gap: 10px;
                            .icon {
                                border-radius: 50% !important;
                                background: #f3f3f3;
                                height: 60px;
                                width: 60px;
                                display: grid;
                                place-items: center;
                            }
                            .details {
                                .bold {
                                    font-size: 16px;
                                    font-weight: 650;
                                    text-wrap: wrap;
                                }
                                .desc {
                                    font-size: 16px;
                                    text-wrap: wrap;
                                    font-weight: 400;
                                }
                            }
                        }
                        .primary-btn-black {
                            padding: 11px 22px;
                            font-size: 12px;
                            height: fit-content;
                        }
                    }
                    .code:last-child {
                        border-bottom: none;
                    }
                }
                input:focus + .coupon-codes {
                    display: block;
                }
            }
            .coins {
                display: flex;
                gap: 10px;
                align-items: center;
            }
            .cod {
                display: flex;
                flex-direction: column;
                gap: 10px;
                position: relative;
                border: 1px solid #808080;
                padding: 20px;
                border-radius: 8px;
                .title {
                    color: #808080;
                    font-size: 16px;
                    font-weight: 650;
                }
                select {
                    width: 100%;
                    border: 1px solid #000;
                    padding: 14px 20px;
                    font-size: 13px;
                    font-weight: 650;
                    color: #808080;
                    -webkit-appearance:none;
                    -moz-appearance:none;
                    appearance:none;
                }
            }
            .buttons {
                display: flex;
                gap: 20px;
                justify-content: space-between;
                align-items: center;
                .total {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    padding: 10px 20px;
                    background: #e8e8e8;
                    font-size: 16px;
                    font-weight: 300;
                    width: 100%;
                    height: 100%;

                    span {
                        font-weight: 650;
                    }
                }
                button {
                    padding: 10px 20px;
                    font-size: 16px;
                    width: 100%;
                    height: 100%;
                    justify-content: center;
                }
            }
        }
    }
    .section-2 {
        margin: 60px 0;

        .head {
            h3 {
                color: $--my-black;
                font-size: 48px;
                font-weight: 700;
                text-transform: uppercase;
            }
            .text-container {
                display: flex;
                justify-content: space-between;
                gap: 30px;
                font-size: 24px;
                font-weight: 500;
                .shop {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;
                    svg {
                        height: 34px;
                        width: 34px;
                    }
                }
            }
        }
        .products {
            padding: 20px 0px 20px 0px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 16px;
            .product {
                cursor: pointer;
                img {
                    width: 100%;
                    // max-height: 625px;
                }
                .loading-statistics-container {
                    width: 100%;
                    max-height: 625px;
                    aspect-ratio: 0.66;
                }
                .content {
                    font-size: 22px;
                    .line1 {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        .title {
                            color: $--my-black;
                            font-weight: 400;
                        }
                        .tag {
                            color: $--secondary-font-color;
                            font-size: 20px;
                            font-weight: 500;
                        }
                    }
                    .bold {
                        color: $--my-black;
                        font-weight: 650;
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .checkout-main {
        padding: 30px;
        gap: 10px;
        .checkout-container {
            .cart-details {
                .buttons {
                    flex-direction: column;
                }
            }
        }
    }
    .section-2 {
        .products {
            .product {
                .content {
                    font-size: 16px;
                    .line1 {
                        .tag {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 900px) {
    .checkout-main {
        margin-top: 70px;
        .checkout-container {
            flex-direction: column;
            .cart-details {
                width: 100%;
                .buttons {
                    flex-direction: row;
                    // flex-wrap: wrap;

                    button .total {
                        height: 100%;
                    }
                }
            }
        }
        .section-2 {
            margin: 20px 0;
            .head {
                h3 {
                    font-size: 36px;
                }
                .text-container {
                    font-size: 16px;
                    .shop {
                        svg {
                            height: 24px;
                            width: 24px;
                        }
                    }
                }
            }
            .products {
                grid-template-columns: 1fr 1fr;
                .product {
                    .content {
                        font-size: 14px;
                        .line1 {
                            .tag {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 600px) {
    .checkout-main {
        padding: 20px;
        h3 {
            font-size: 48px;
        }
        .checkout-container {
            .cart-details {
                .buttons {
                    flex-direction: column;
                }
            }
        }
        .section-2 {
            .head {
                .text-container {
                    font-size: 12px;
                    .shop {
                        svg {
                            height: 16px;
                            width: 16px;
                        }
                    }
                }
            }
            .products {
                grid-template-columns: 1fr;
                gap: 0;
                .product {
                    .content {
                        display: none;
                    }
                }
            }
        }
    }
}
@media (max-width: 400px) {
    .checkout-main {
        .checkout-container {
            .cart-details {
                .buttons {
                    flex-direction: column;
                }
            }
        }
    }
}
