@import "/src/variables";
@import "/src/index.scss";

.select-address {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    
    .address-list {
        max-height: 550px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 20px;
        
        .address {
            display: flex;
            gap: 20px;
            justify-content: space-between;
            align-items: center;
            background-color: #f9f9f9;
            padding: 16px;
            border-radius: 20px;
            .address-details {
                display: flex;
                align-items: center;
                gap: 20px;
                .details {
                    p {
                        font-size: 16px;
                    }
                    .bold {
                        font-weight: 600;
                    }
                }
            }
            .links {
                display: flex;
                gap: 16px;
                flex-wrap: wrap;

                p {
                    cursor: pointer;
                    text-decoration: underline;
                }
                .delete {
                    color: red;
                }
            }
        }
    }
    .add-address {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 27px;
        background: #e8e8e8;
        font-size: 16px;
        font-weight: 300;
        width: 100%;
        font-weight: 500;
        cursor: pointer;
    }
}
@media (max-width: 400px){
    .select-address{
        .address-list{

            .address{
                
                flex-direction: column;
                }
            }
    }
}