@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $--primary-font-family;
}

.disable-scroll {
    overflow: hidden;
}
p {
    margin-bottom: 0;
}

.primary-btn-white {
    padding: 12px 16px;
    background-color: $--my-white;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 16px;
    color: $--my-black;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.4px;
    outline: none;
    border: none;
    transition: 0.2s transform ease-in-out;
    will-change: transform;
    position: relative;
    cursor: pointer;

    &::after {
        background-color: transparent;
        // border-radius: 3rem;
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.2s transform ease-out;
        will-change: transform;
        z-index: -1;
    }
    
    &:hover::after {
        display: block;
        background-color: black;
    }
    
    &:hover {
        border: 2px solid transparent;
        color: white;
        transform: scale(1.05);
        will-change: transform;

        svg{
            path{
                fill: white;
            }
        }
    }
}
.primary-btn-white-outline {
    padding: 18px 18px;
    background-color: $--my-white;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 16px;
    color: $--my-black;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    text-wrap: nowrap;
    font-weight: 500;
    letter-spacing: 0.4px;
    outline: none;
    border: 2px solid $--my-black;
    transition: 0.2s transform ease-in-out;
    will-change: transform;
    position: relative;
    cursor: pointer;

    &::after {
        background-color: transparent;
        // border-radius: 3rem;
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.2s transform ease-out;
        will-change: transform;
        z-index: -1;
    }
    
    &:hover::after {
        display: block;
        background-color: black;
    }
    
    &:hover {
        border: 2px solid transparent;
        color: white;
        transform: scale(1.05);
        will-change: transform;

        svg{
            path{
                fill: white;
            }
        }
    }
}
.primary-btn-red {
    padding: 16px 20px;
    background-color: $--my-red;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 16px;
    color: $--my-white;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.4px;
    outline: none;
    border: none;
    cursor: pointer;

    svg {
        height: 24px;
        width: 24px;
        path {
            fill: $--my-white;
        }
    }
}

.primary-btn-black {
    padding: 24px 36px;
    background-color: $--my-black;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    color: $--my-white;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.4px;
    outline: none;
    border: none;
    transition: 0.2s transform ease-in-out;
    will-change: transform;
    position: relative;
    cursor: pointer;

    &::after {
        background-color: transparent;
        // border-radius: 3rem;
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.2s transform ease-out;
        will-change: transform;
        z-index: -1;
    }
    
    &:hover::after {
        display: block;
        background-color: black;
    }
    
    &:hover {
        border: 2px solid transparent;
        color: white;
        transform: scale(1.05);
        will-change: transform;

        svg{
            path{
                fill: white;
            }
        }
    }
}
// .primary-btn-black:hover {
//     opacity: 0.85;
// }
.my-btn {
    color: var(--text-color, #000000);
    font-size: 16px;
    font-weight: 500;
    padding: 12px 16px;
    border: 1px solid #e6e6e6;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    cursor: pointer;

    svg {
        height: 20px;
        width: 20px;
    }
}
.my-btn:hover {
    opacity: 0.85;
}
.form-error {
    font-size: 14px;
    color: red;
}
.nav-offcanvas {
    p {
        margin: 0;
    }
    .offcanvas-header {
        width: 100%;
        color: $--my-white;
        background-color: $--my-black;
        display: flex;
        justify-content: space-between;

        .coins {
            padding: 8px;
            border-radius: 1000px;
            border: 1px solid $--my-white;
            display: flex;
            gap: 8px;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
        }
    }
    .offcanvas-body {
        background-color: $--my-black;
        color: $--my-white;
        display: flex;
        flex-direction: column;
        gap: 30px;

        span {
            display: flex;
            gap: 8px;
            align-items: center;
            svg {
                height: 24px;
                width: 24px;

                path {
                    fill: $--my-white;
                }
            }
        }
        .buttons {
            display: flex;
            align-items: center;
            gap: 20px;

            button {
                width: 100%;
                display: grid;
                place-items: center;
            }
        }
    }
}

.backdrop {
    background-color: $--my-black;
    opacity: 0.5;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
}
.check-box {
    display: grid;
    place-items: center;
    min-width: 24px;
    min-height: 24px;
    height: 24px;
    width: 24px;
    border: 2px solid $--my-black;
    border-radius: 8px;
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

// for Firefox add this class as well
.thin_scroll {
    scrollbar-width: thin; // auto | thin | none | <length>;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #d9d9d9;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #c4c4c4;
}

@media (max-width: 1250px) {
    .primary-btn-black {
        font-size: 16px;
    }
}

@media (max-width: 600px) {
    .primary-btn-red {
        font-size: 16px;
        padding: 10px 14px;
    }
    .primary-btn-black {
        font-size: 12px;
        padding: 10px 20px;
        svg {
            min-height: 16px;
            min-width: 16px;
        }
    }
}

.component-loading{
    background: linear-gradient(to right, #F0F0F0, #d9d9d9, #F0F0F0);
    background-size: 200%;
    height: 33%;
    animation: loading 1s infinite;
  }
  @keyframes loading {
    0% {
        background-position: -100%;
    }
    100% {
        background-position: 100%;
    }
  }