@import "/src/variables";
@import "/src/index.scss";

.footer-main {
    display: flex;
    flex-direction: column;
    padding: 40px;
    background: #010101;

    p{
        margin: 0;
    }
    h3 {
        text-transform: uppercase;
        color: #fafafa;
        font-family: "Bebas Neue";
        font-size: 100px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 40px;
    }
    .container {
        padding: 30px 0;
        display: flex;
        justify-content: space-evenly;
        gap: 30px;
        color: $--my-white;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;

        .manues {
            display: flex;
            gap: 30px;
            span{
                cursor: pointer;
            }
        }
    }
}
.footer-main-mobile {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 16px;
    display: none;

    p{
        margin: 0;
    }

    .subscribe {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .details {
            h4 {
                color: var(--Text-900, #101113);
                font-family: Poppins;
                font-size: 18px;
                font-weight: 500;
                text-transform: uppercase;
            }
            p {
                color: var(--Text-400, #5c5f66);
                font-size: 16px;
                font-weight: 400;
            }
        }
        .input-container {
            display: flex;
            gap: 10px;

            input {
                width: 100%;
                border: 1px solid var(--Neutral-300, #d6d6d6);
                outline: none;
                padding: 12px;
                color: var(--Text-300, #a6a7ab);
                font-size: 14px;
                font-weight: 400;
            }
        }
        .logos {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 30px;
            .socials {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
            }
        }
    }
    .my-links {
        display: flex;
        gap: 40px;
        flex-wrap: wrap;
        .container {
            display: flex;
            flex-direction: column;
            gap: 8px;
            span {
                display: inline-block;
                color: var(--Grey-grey-500, #6b7280);
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
            }
            .bold {
                color: var(--Grey-grey-800, #1f2937);
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }
    .we-accept{
        p{
            color: var(--Grey-grey-800, #1F2937);
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 16px;
        }
        .logos{
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
        }
    }
    .policies{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 6px;

        p{
            color: var(--Grey-grey-500, #6B7280);
            font-size: 12px;
            font-family: Poppins;
            font-weight: 500;
        }
        span{
            display: inline-block;
            width: 2px;
            height: 16px;
            background-color: #E5E7E8;
        }
    }
    .copyrights{
        color: var(--Text-300, #A6A7AB);
        font-size: 14px;
        text-align: center;
        font-weight: 400;
        margin-bottom: 40px;
    }
}

@media (max-width: 800px){
    .footer-main{
        display: none;
    }
    .footer-main-mobile{
        display: flex;
    }
}