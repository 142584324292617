@import "/src/variables";
@import "/src/index.scss";

.address-selector-main {
    width: 50%;
}
.full-view {
    width: 100%;
}

@media (max-width: 1500px) {
    .full-view {
        .address-main {
            .inputs-container {
                grid-template-columns: 1fr;
                gap: 12px;
            }
        }
    }
}
@media (max-width: 1100px) {
    .full-view {
        .address-main {
            .inputs-container {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}
@media (max-width: 900px) {
    .address-selector-main {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .full-view {
        .address-main {
            .inputs-container {
                grid-template-columns: 1fr;
            }
        }
    }
}
